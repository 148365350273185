<template>
  <div class="setting-box">
    <nav-bar :title="$t('隱私中心')" :isFixed="true" :goBack="goBack" :bgc="'background: #ffffff'" />
    <div class="box-main">
      <div class="user-item" @click="handleRouteChange('3')">
        <div class="left">
          <div>{{ $t("隱私政策") }}</div>
        </div>
        <img src="@/assets/icons/arrow-right.png" alt="" />
      </div>
      <div class="user-item" @click="handleRouteChange('4')">
        <div class="left">
          <div>{{ $t("平台服務協定") }}</div>
        </div>
        <img src="@/assets/icons/arrow-right.png" alt="" />
      </div>
    </div>
  </div>
</template> 
<script>
import mixins from "@/utils/mixins.js"
import { mapState } from "vuex"
export default {
  name:"userPrivacyCenter",
  mixins: [mixins],
  computed: {
    ...mapState("user", ["userInfo"])
  },
  data(){
    return{
      lang: "English",
      langEnv: 2,
      columns: [
        {
          value: "2",
          label: "English"
        },
        {
          value: "3",
          label: "繁體中文"
        }
      ],
    }
  },
  created(){
    this.$store.dispatch("user/getUserInfo")
    this.langEnv = window.localStorage.getItem("langEnv") || 2
    this.lang =
      window.localStorage.getItem("langEnv") == 2 ? "English" : "繁體中文"
  },
  methods:{
    handleRouteChange(path){
      this.$router.push(`/about?id=${path}&langEnv=${this.langEnv}`)
    },
    goBack () {
      this.$router.back()
    }
  }
}
</script>
<style lang="scss" scoped>
.setting-box{
  padding-top:44px;
  box-sizing: border-box;
  background:#FFFFFF;
  min-height: 100vh;
  .box-main{
    margin:16px 10px 24px;
    padding:0 16px;
    box-sizing: border-box;
    background:#ffffff;
    border-radius: 12px 12px 12px 12px;
    .user-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 18px 0;
    border-bottom: 1px solid #F8F8F8;
    // &:last-child{
    //     border:0
    //   }
    img {
      width: 16px;
      height: 16px;
    }

    .left {
      display: flex;
      align-items: center;
      white-space: nowrap;
      font-weight: bold;

      img {
        height: 32px;
        width: 32px;
      }
    }
    .lang {
    display: flex;
    align-items: center;
    color: #999999;
    div{
      font-weight: bold;
    }
  }
  }
  }
}
</style>